.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #282c34;
  color: white;
}

.chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1000px;
}

.message-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 20px;
  overflow-y: auto;
  max-height: 70vh;
  min-height: 70vh;
  width: 100%;
  background-color: #ffffffb6;
}

.message {
  display: flex;
  align-items: center;
  max-width: 50%;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #f1f0f0;
  position: relative; /* Set position to relative */
}

.message p {
  margin: 0;
  text-transform: capitalize;
}

.user {
  align-self: flex-end;
  background-color: #646566;
  color: #fff;
  margin-right: 60px;
}

.bot {
  align-self: flex-start;
  background-color: #1b4297;
  color: #fff;
}

.icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.user .icon-wrapper {
  right: -15px;
  top:40px
}

.user .message-content{
  margin-left: 0px;
}

.user p{
  transform: translateY(-50%);
}

.bot .icon-wrapper {
  left: 10px;
}

.icon {
  position: absolute;
  transform: translateY(-90%);
  width: 40px;
  height: 40px;
}

.bot-image {
  width: 40px;
  height: 40px;
  margin-right: -30px;
}

.message-content {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.input-container {
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 10px 0;
}

.input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border-radius: 10px;
  height: 35px;
  border: 1px solid #ccc;
}

.button {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #fff;
  background-color: #144997;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

.error {
  color: red;
  margin-top: 10px;
}


.message-container::-webkit-scrollbar {
  display: none; 
}

.see-more{
  cursor: pointer;
}
